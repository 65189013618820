<template>
  <div class="scenarios">
    <PageTitle
      title="Сценарии"
      subtitle="Всего сценариев"
      :subtitleNumber="getScenarios.itemsTotal ? getScenarios.itemsTotal : 0"
      classListInfo="list-info-in-count--m0"
    >
      <template v-slot:button>
        <HeaderButtons
          :exportInProgress="exportInProgress"
          @editCard="editCard"
          @exportExcel="exportExcel"
          @exportWord="exportWord"
        />
      </template>
    </PageTitle>

    <div class="table-sort">
      <TableHeader
        columnCode="Код ТН ВЭД"
        columnNameProduct="Товар"
        columnTradeType="Тип торговой операции"
        columnDepartureCountry="Страна отправления"
        columnDestinationCountry="Страна назначения"
        columnKindTransport="Вид транспорта"
        columnStatus="Статус"
        columnDateCreation="Дата создания"
        columnDateChange="Дата изменения"
        :publicSortColumn="request.sortColumn"
        :publicSortOrder="request.sortOrder"
        v-model:codeFilter="filter.tnved"
        v-model:nameProductFilter="filter.name"
        v-model:tradeTypeFilter="filter.tradingOperationIds"
        v-model:departureCountryFilter="filter.departureCountryIds"
        v-model:destinationCountryFilter="filter.destinationCountryIds"
        v-model:kindTransportFilter="filter.transportIds"
        v-model:statusFilter="filter.isActive"
        v-model:dateCreationFromFilter="filter.createdStart"
        v-model:dateCreationToFilter="filter.createdEnd"
        v-model:dateChangeFromFilter="filter.modifiedStart"
        v-model:dateChangeToFilter="filter.modifiedEnd"
        @changeSorting="changeSorting"
        @changeFilterDepartureCountry="changeFilterDepartureCountry"
        @changeFilterDestinationCountry="changeFilterDestinationCountry"
      />

      <div class="table-sort__body" v-if="getScenarios.items?.length && !getIsLoading">
        <div
          class="table-sort__row hover"
          v-for="item in getScenarios.items"
          :key="item.id"
          @dblclick="editCard(item.id)"
        >
          <div class="table-sort__td column-icon">
            <div class="wrap-icon">
              <IconComponent class="icon--full-svg" @click="openCloneCardModal(item)" name="copy3" />
              <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
              <IconComponent
                class="icon--full-svg delete"
                @click="openClarifyingPopup(item.id)"
                name="delete-not-fill"
              />
            </div>
          </div>
          <div class="table-sort__td column-number">
            <p>{{ item.id }}</p>
          </div>
          <div class="table-sort__td column-code">
            <p>{{ item.tnved }}</p>
          </div>
          <div class="table-sort__td column-name-product">
            <p>{{ item.name }}</p>
          </div>
          <div class="table-sort__td column-trade-type">
            <p>{{ item.tradingOperationId === Number(TRADING_OPERATION_ID.export) ? 'Экспорт' : 'Импорт' }}</p>
          </div>
          <div class="table-sort__td column-departure-country">
            <p>{{ item.departureCountry.name }}</p>
          </div>
          <div class="table-sort__td column-destination-country">
            <p>{{ item.destinationCountry.name }}</p>
          </div>
          <div class="table-sort__td column-kind-transport">
            <p>
              {{ getTableTransportInfo(item) }}
            </p>
          </div>
          <div class="table-sort__td column-status">
            <p>{{ item.isActive ? 'Действующий' : 'Не действующий' }}</p>
          </div>
          <div class="table-sort__td column-date-creation">
            <p>
              {{ $momentFormat(item?.created, DATE_FORMATS['DD.MM.YYYY']) }}
              <br />
              {{ $momentFormat(item?.created, DATE_FORMATS['HH:mm']) }}
            </p>
          </div>
          <div class="table-sort__td column-date-change">
            <p>
              {{ $momentFormat(item?.modified, DATE_FORMATS['DD.MM.YYYY']) }}
              <br />
              {{ $momentFormat(item?.modified, DATE_FORMATS['HH:mm']) }}
            </p>
          </div>
        </div>
        <div v-if="getScenarios.items.length < 4" class="table-sort__row stub"></div>
        <div v-if="getScenarios.items.length < 3" class="table-sort__row stub"></div>
        <div v-if="getScenarios.items.length < 2" class="table-sort__row stub"></div>
      </div>
      <div v-else class="table-sort__body">
        <div class="table-sort__row stub">
          <div class="table-sort__td stub">
            <Loader v-if="getIsLoading" />
            <template v-else> По вашему запросу товары не найдены </template>
          </div>
        </div>
        <div class="table-sort__row stub"></div>
        <div class="table-sort__row stub"></div>
        <div class="table-sort__row stub"></div>
      </div>
    </div>
    <div v-if="getScenarios.pagesTotal > 1" class="scenarios__pagination">
      <PaginationRedesigned
        :pageCount="getScenarios.pagesTotal"
        :currentPage="getScenarios.pageNumber"
        :loadingMore="getIsLoadingMore"
        @change="onPageChange($event)"
        @more="onPageMore($event)"
      />
    </div>
  </div>

  <ModalComponent
    title="Копирование сценария"
    @close="closeModal"
    v-model="cloneModal"
    headType="redesigned"
    bodyType="redesigned"
  >
    <div class="custom-form">
      <div class="custom-form__block">
        <div class="custom-form__inputBlock">
          <p class="custom-form__label required">Название сценария</p>
          <ComponentInput
            mod="redesigned"
            placeholder="Укажите название для нового сценария"
            v-model="cloneCard.newName"
            :error="v$.cloneCard.$error"
            required
          />
        </div>
        <div class="custom-form__inputBlock">
          <p class="custom-form__label">Страны</p>
          <SelectMultiComponentCheckbox
            v-model="cloneCard.newCountryIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="getCountryList"
          />
        </div>
        <div class="custom-form__inputBlock">
          <p class="custom-form__label">Товар (ТН ВЭД)</p>
          <SelectionGoods
            :multiple="false"
            :selectedIdsProps="cloneCard.newTnvedIds"
            @updateSelectedIDs="updateSelectedIDs"
          />
        </div>
        <div class="custom-form__button-container">
          <ButtonComponent @click="cloneScenario" :disabled="getDisabledButtonClone" mod="gradient-bg"
            >Копировать</ButtonComponent
          >
          <ButtonComponent @click="closeModal" :disabled="exportInProgress">Отменить</ButtonComponent>
        </div>
      </div>
    </div>
  </ModalComponent>

  <!-- подтверждение удаления -->
  <ModalInfo
    v-model="isOpenModalClarifying"
    titleModal="Вы действительно хотите удалить сценарий?"
    @closeModal="closeAllModal"
  >
    <div class="content-modal-info">
      <div class="content-modal-info__button-wrap">
        <ButtonComponent @click="deleteCard">Удалить</ButtonComponent>
        <ButtonComponent @click="closeAllModal" mod="gradient-bg">Отменить</ButtonComponent>
      </div>
    </div>
  </ModalInfo>

  <!-- Окно оповещения -->
  <ModalInfo
    v-model="isOpenModalInfo"
    :titleModal="titleModal"
    :textModal="textModal"
    :modalIconName="modalIconName"
    @closeModal="closeModalInfo"
  />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { debounce } from 'lodash';

  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import SelectionGoods from '@/common/components/redesigned/SelectionGoods.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import IconComponent from '@/common/ui/IconComponent';

  import Loader from '@/components/Loader';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import CatalogApi from '@/modules/nsi/api/catalog';

  import API from '../api/scenario';
  import HeaderButtons from '../components/scenario/HeaderButtons.vue';
  import TableHeader from '../components/table/TableHeader.vue';
  import { NAVIGATOR_SCENARIOS_ACTIONS_NAME, NAVIGATOR_SCENARIOS_GETTERS_NAME } from '../store/scenarios';
  import { MODAL_INFO_MESSAGES, TRADING_OPERATION_ID } from '../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'Scenarios',
    components: {
      IconComponent,
      ModalComponent,
      ComponentInput,
      PageTitle,
      ButtonComponent,
      PaginationRedesigned,
      TableHeader,
      Loader,
      SelectMultiComponentCheckbox,
      ModalInfo,
      HeaderButtons,
      SelectionGoods,
    },
    mixins: [modalInfo],
    data() {
      return {
        tnvedList: [{ text: 'Все товары', id: 0 }],
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          // publicSortColumn:
          // 0 - Код ТН ВЭД
          // 1 - Наименование сценария
          // 2 - Тип торговой операции
          // 3 - Страна отправления
          // 4 - Страна назначения
          // 5 - Статус
          // 6 - Дата создания
          // 7 - Дата изменения
          sortColumn: null,
          // publicSortOrder:
          // 0 - по возрастанию
          // 1 - по убыванию
          sortOrder: null,
        },
        DATE_FORMATS: Constants.DATE_FORMATS,
        filter: {
          tnved: null,
          name: null,
          tradingOperationIds: [],
          departureCountryIds: [],
          destinationCountryIds: [],
          transportIds: [],
          isActive: null,
          createdStart: null,
          createdEnd: null,
          modifiedStart: null,
          modifiedEnd: null,
        },
        exportInProgress: false,
        cloneModal: false,
        cloneCard: {
          sourceId: null,
          newName: '',
          newTnvedIds: [],
          newCountryIds: [],
        },
        cloneInProgress: false,

        copiedCardId: null,
        deleteItemId: null,
        isOpenModalClarifying: false,

        TRADING_OPERATION_ID,
      };
    },
    setup: () => ({ v$: useVuelidate() }),

    created() {
      this.loadPage();
    },
    validations() {
      return {
        cloneCard: {
          newName: { required },
        },
      };
    },
    methods: {
      ...mapActions({
        fetchScenarios: NAVIGATOR_SCENARIOS_ACTIONS_NAME.fetchScenarios,
        fetchMoreScenarios: NAVIGATOR_SCENARIOS_ACTIONS_NAME.fetchMoreScenarios,
        fetchCountries: DIRECTIVES_ACTIONS_NAME.countriesGet,
      }),
      updateSelectedIDs(ids) {
        this.cloneCard.newTnvedIds = ids;
      },
      getTableTransportInfo(item) {
        return this.getTransportList
          .filter((transport) => item.scenarioTransportIds.includes(transport.id))
          .map((x) => x.text)
          .join(', ');
      },
      closeAllModal() {
        this.isOpenModalClarifying = false;
        this.closeModalInfo();
      },
      openClarifyingPopup(id) {
        this.deleteItemId = id;
        this.isOpenModalClarifying = true;
      },

      changeSorting(id) {
        if (this.request.sortColumn == id) this.request.sortOrder = this.request.sortOrder === 0 ? 1 : 0;
        else this.request.sortOrder = 0;
        this.request.sortColumn = id;
        this.loadPage();
      },
      getQueryParams() {
        const queryParams = { ...this.filter, ...this.request };
        if (this.filter.isActive) {
          queryParams.isActiveValues = this.filter.isActive === Number(Constants.statusOptionsIDs.active);
        }
        queryParams.isActive && delete queryParams.isActive;
        return queryParams;
      },
      loadPage() {
        const queryParams = this.getQueryParams();
        this.fetchScenarios(queryParams);
      },
      loadPageMore() {
        const queryParams = this.getQueryParams();
        this.fetchMoreScenarios(queryParams);
      },
      editCard(id) {
        this.$router.push({ name: 'ScenarioCard', params: { id: id, action: 'edit' } });
      },
      deleteCard() {
        this.isOpenModalClarifying = false;
        API.delete(this.deleteItemId)
          .then(() => {
            this.isOpenModalInfo = true;
            this.titleModal = MODAL_INFO_MESSAGES.REMOVE_TITLE;
            this.textModal = MODAL_INFO_MESSAGES.REMOVE_SCENARIO_SUCCESS;
            this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
            this.loadPage();
          })
          .catch((error) => {
            this.isOpenModalInfo = true;
            this.titleModal = MODAL_INFO_MESSAGES.REMOVE_TITLE;
            this.textModal = MODAL_INFO_MESSAGES.REMOVE_SCENARIO_ERROR;
            this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
            console.error(error);
          });
      },
      onPageChange(pageNumber) {
        this.request.pageNumber = pageNumber;
        this.loadPage();
      },
      onPageMore(pageNumber) {
        this.request.pageNumber = pageNumber;
        this.loadPageMore();
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.loadPage();
      },
      debounceChangeFilter: debounce(function () {
        this.changeFilter();
      }, 500),
      exportExcel() {
        this.exportInProgress = true;
        const params = { ...this.filter, ...this.request };
        API.exportExcel(params).finally(() => {
          this.exportInProgress = false;
        });
      },
      exportWord() {
        this.exportInProgress = true;
        const params = { ...this.filter, ...this.request };
        API.exportWord(params).finally(() => {
          this.exportInProgress = false;
        });
      },
      openCloneCardModal(item) {
        this.v$.$reset();
        this.cloneCard.sourceId = item.id;
        this.cloneCard.newCountryIds = [];
        this.cloneCard.newName = item.name;
        this.cloneCard.newTnvedIds = item.tnvedIds;

        this.cloneModal = true;
      },
      cloneScenario() {
        this.v$.$touch();
        if (!this.v$.cloneCard.$invalid) {
          this.cloneInProgress = true;
          API.clone(this.cloneCard)
            .then((response) => {
              this.copiedCardId = response.data.id;
              this.titleModal = 'Сохранено';
              this.textModal = MODAL_INFO_MESSAGES.COPY_SCENARIO_SUCCESS;
              this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
              this.isOpenModalInfo = true;
              this.closeModal();
              this.loadPage();
            })
            .catch(() => {
              this.titleModal = 'Ошибка';
              this.textModal = MODAL_INFO_MESSAGES.COPY_SCENARIO_ERROR;
              this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
              this.isOpenModalInfo = true;
              this.closeModal();
            });
        }
      },
      closeModal() {
        this.cloneCard.sourceId = null;
        this.cloneCard.newName = '';
        this.cloneCard.newTnved = [];
        this.cloneCard.newCountryId = [];

        this.cloneInProgress = false;
        this.cloneModal = false;
      },

      changeFilterDepartureCountry(val) {
        if (val) this.filter.departureCountry = [Constants.countryRussiaId];
        else this.filter.departureCountry = [];
      },

      changeFilterDestinationCountry(val) {
        if (val) this.filter.destinationCountry = [Constants.countryRussiaId];
        else this.filter.destinationCountry = [];
      },
    },
    computed: {
      ...mapGetters({
        getScenarios: NAVIGATOR_SCENARIOS_GETTERS_NAME.getScenarios,
        getIsLoading: NAVIGATOR_SCENARIOS_GETTERS_NAME.getIsLoading,
        getIsLoadingMore: NAVIGATOR_SCENARIOS_GETTERS_NAME.getIsLoadingMore,
        getTransportList: DIRECTIVES_GETTERS_NAME.getTransportList,
        getCountryList: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
      }),
      getDisabledButtonClone() {
        return !this.cloneCard.newName || this.cloneInProgress;
      },
    },
    watch: {
      filter: {
        handler() {
          this.debounceChangeFilter();
        },
        deep: true,
      },
      'cloneCard.tnvedIds'(value) {
        if (!Object.values(value).length) return;
        CatalogApi.getListByIds('nsitnved', value).then((response) => {
          const list = response.data.map((x) => ({ id: x.id, text: x.name }));
          list.forEach((item) => {
            if (!this.tnvedList.find((x) => x.id === item.id)) {
              this.tnvedList.push(item);
            }
          });
        });

        if (this.cloneCard.tnvedIds.length > 1 && this.cloneCard.tnvedIds.includes('0'))
          this.cloneCard.tnvedIds = ['0'];
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/table/table-sort.scss';
  @import '@/assets/theme/default/custom-form.scss';
  @import '@/assets/theme/default/content-modal-info.scss';
  .scenarios {
    margin-bottom: 40px;

    @media (max-width: $mobile) {
      margin-bottom: 24px;
    }

    &__modal-info-push {
      margin-top: 24px;
    }
  }
</style>
